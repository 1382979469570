import React from 'react';
import styled from 'styled-components';
import { Link, graphql } from 'gatsby';

import Layout from '@common/Layout';
import Navbar from '@common/Navbar';
import ProjectList from '@common/ProjectList';
import { Container, Section } from '@components/global';
import Button from '@common/Button';
import Footer from '@sections/Footer';

const ProjectsPage = ({ data }) => (
  <Layout>
    <Navbar />
    <Section>
      <Container>
        <h2>Projekt</h2>
        <ProjectList projects={data.allMarkdownRemark.nodes} />
        <Buttons>
          <Link to="/">
            <Button primary>Tillbaka</Button>
          </Link>
        </Buttons>
      </Container>
    </Section>
    <Footer />
  </Layout>
);

export default ProjectsPage;

export const query = graphql`
  query ProjectsQuery {
    allMarkdownRemark(
      limit: 20
      sort: { fields: [frontmatter___date], order: DESC }
    ) {
      nodes {
        frontmatter {
          date
          images {
            childImageSharp {
              fluid(maxWidth: 3840) {
                base64
                aspectRatio
                src
                srcSet
                srcWebp
                srcSetWebp
                sizes
              }
            }
          }
          title
          type
        }
        fields {
          slug
        }
        excerpt(format: PLAIN, pruneLength: 140, truncate: false)
      }
    }
  }
`;

const Buttons = styled.div`
  margin: 20px 0;
  display: flex;
  justify-content: center;
`;
